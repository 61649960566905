<template>
  <section class="mt-4 pb-5">
    <div class="container">
      <div class="inner_page_two_sides">

        <div class="right_side">

          <div class="section_payment_breadcrumbs">
            <span><a href="/">{{ $t('common.main_page') }}</a></span>
            <span>{{ $t('common.partners') }}</span>
          </div>

          <div class="section_header mt-3 mb-3">
            <span>{{ $t('common.partners') }}</span>
          </div>

          <div class="section_content section_content_partners">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6 col-12">
                <div class="box">
                  <div class="box_title">{{ $t('partners.partners.users') }}</div>
                  <div class="box_description">{{ $t('partners.partners.users_text') }}</div>
                  <div class="box_button"><button @click="toUsers">{{ $t('partners.partners.more') }}</button></div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-6 col-12">
                <div class="box">
                  <div class="box_title">{{ $t('partners.partners.monitorings') }}</div>
                  <div class="box_description">{{ $t('partners.partners.monitorings_text') }}</div>
                  <div class="box_button"><button @click="toMonitoring">{{ $t('partners.partners.join') }}</button></div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>

    </div></section>
</template>

<script>

import router from "../../router";

export default {
  name: 'Partners',


  methods: {
    toUsers: function () {
      router.push('partners/users')
    },

    toMonitoring: function () {
      router.push('partners/monitoring')
    }
  },
}
</script>
